<template>
  <div>
    <AppHeaderTitle :Header="Header" />
    <AppHeaderInfo :infos="infos" />

    <b-card ref="formContainer">
      <b-table :fields="fields" :items="Configs" :small="small">
        <template #cell(key)="key">
          <b-badge variant="primary">
            {{ key.item.key }}
          </b-badge>
        </template>
        <template #cell(value)="key">
          {{ !key.item.multi ? key.item.value : key.item.values.join(",") }}
        </template>
        <!-- Column: Actions -->
        <template #cell(action)="row">
          <b-button variant="flat-primary" @click="row.toggleDetails" size="sm">
            <feather-icon icon="EditIcon" />
            editer
          </b-button>
        </template>
        <template #row-details="row">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="6">
              <b-input-group>
                <b-form-input
                  :value="getValue(row.item)"
                  v-model="row.item.value"
                  size="sm"
                />
                <b-input-group-append>
                  <b-button
                    @click="editConfig(row.item.key, row.item.value)"
                    variant="success"
                    size="sm"
                  >
                    Editer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
	BTable,
	BCard,
	BButton,
	BBadge,
	BInputGroup,
	BFormInput,
	BInputGroupAppend,
	BCol,
	BRow,
} from "bootstrap-vue";
import AppHeaderTitle from "@/app/views/components/AppHeaderTitle.vue";
import AppHeaderInfo from "@/app/views/components/AppHeaderInfo.vue";
import { Config } from "@/mixins/config.js";
export default {
	components: {
		BTable,
		BCard,
		AppHeaderTitle,
		BButton,
		BBadge,
		BInputGroup,
		BFormInput,
		BInputGroupAppend,
		BCol,
		BRow,
		AppHeaderInfo,
	},
	mixins: [Config],
	data() {
		return {
			Configs: [],
			small: true,
			fields: [
				{ key: "key", label: "KEY" },
				{ key: "info", label: "INFORMATION" },
				{ key: "value", label: "VALEUR" },
				"ACTION",
			],
			Header: {
			config: "configuration",
			listLink: "",
			list: "Générale",
			},
			infos: {
				title: "Information",
				content: "texte explicatif de la page",
			},
		};
	},
	async mounted() {
		await this.loadConfigs();
	},
	methods: {
		getValue(item) {
			return !item.multi ? item.value : item.values.join(",");
		},
		async loadConfigs() {
			let loader = this.$loading.show({
			container: this.$refs.formContainer,
			canCancel: true,
			});
			try {
				const datas = await this.getAll();
				if (datas.status === 1) {
					this.Configs = datas.datas;
				} else {
					this.$sweetError("AF-18");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("AF-18");
			}
		},
		async editConfig(key, value) {
            console.log("🚀 ~ file: config-list.vue ~ line 120 ~ editConfig ~ key", key, value)
			let loader = this.$loading.show({
			container: this.$refs.formContainer,
			canCancel: true,
			});
			try {
				const response = await this.updateConfig(key, {"value": value});
				if (response.status == 1) {
					this.$sweetNotif();
					loader.hide();
				} else {
					this.$sweetError("AF-18");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("AF-18-1");
			}
		}
	},
};
</script>
