import { api } from "@/services/axios";

export const Config = {
	methods: {
		async getAll() {
			try {
				const response = await api.get("getter/infos/config");
				// console.log('test API', response)
				return {
					status: 1,
					datas: response.data.datas,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},
		async getConfigDetail(key) {
			try {
				const response = await api.get(`getter/config/${key}`);
	
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},
		async updateConfig(key, value) {
			try {
				const response = await api.put(`setter/config/${key}`, value);
	
				return {
					status: 1,
					datas: response.data,
				};
			} catch (error) {
				return {
					status: 0,
					message: error.message,
				};
			}
		},
	},
};
